exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Tab_tabs__2ANsb {\n  display: flex; }\n  .Tab_tabs__2ANsb .Tab_tab___5sIf {\n    justify-content: center;\n    align-items: center;\n    width: 6%;\n    padding: 1em 0;\n    border: none;\n    background: #EBF1FE;\n    font-style: normal;\n    font-weight: 500;\n    font-size: 14px;\n    line-height: 24px;\n    color: rgba(58, 53, 65, 0.68); }\n  .Tab_tabs__2ANsb .Tab_tab___5sIf.Tab_active__33ug5 {\n    border-bottom: 2px solid #9155FD;\n    margin-bottom: -2px;\n    color: #9155FD; }\n\n.Tab_panel__Kd7UF {\n  display: none;\n  padding: 10px; }\n\n.Tab_panel__Kd7UF.Tab_active__33ug5 {\n  display: block; }\n", ""]);

// exports
exports.locals = {
	"tabs": "Tab_tabs__2ANsb",
	"tab": "Tab_tab___5sIf",
	"active": "Tab_active__33ug5",
	"panel": "Tab_panel__Kd7UF"
};