import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { ROUTES } from "./constants/routes";
import "./App.scss";
import { Header } from "./Components/Header/Header";
import { Footer } from "./Components/Footer/Footer";
import Multiform from "./Components/pages/Multiform/Multiform";
import { Home } from "./pages/Home/Home.jsx";
import AllCvs from "./Components/pages/AllCvs/AllCvs";
import MyCvs from "./Components/pages/MyCvs/MyCvs.jsx";
import { PublicRoute } from "./utils/PublicRoute";
import { PrivateRoute } from "./utils/PrivateRoute";
import Download from "./Components/Download/Download";
import EmployeeСv from "./Components/pages/EmployeeCv/EmployeeСv"
import { LoginPage } from "./pages/Auth/LoginPage/LoginPage";
import { GlobalProvider } from "./context/Provider";
import TableTemplate from "./Components/TableTemplate/TableTemplate";
import { getLoginToken, getLoginURL } from "./AuthConfig";

export const App = () => {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      setAuthenticated(true);
    }
  }, [window.location]);

  return (
    <GlobalProvider>
      <Header authenticated={authenticated} />
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return authenticated ? <Redirect to={ROUTES.home} /> : <Redirect to={ROUTES.login} />;
          }}
        />
        <PublicRoute
          authenticated={authenticated}
          exact
          component={LoginPage}
          path={ROUTES.login}
        />
        <PublicRoute
          authenticated={authenticated}
          exact
          component={LoginPage}
          path="/authenticated"
        />
        {/*<PublicRoute authenticated={authenticated} exact component={AllCvs} path={ROUTES.allcvs} />*/}
        <PrivateRoute authenticated={authenticated} exact component={AllCvs} path={ROUTES.allcvs} />
        <PrivateRoute authenticated={authenticated} exact component={Home} path={ROUTES.home} />
        <PrivateRoute
          authenticated={authenticated}
          exact
          component={Download}
          path={ROUTES.download}
        />
        <PrivateRoute
          authenticated={authenticated}
          exact
          component={Download}
          path={ROUTES.download}
        />
        <PrivateRoute
          authenticated={authenticated}
          exact
          component={Multiform}
          path={ROUTES.steps}
        />
        <PrivateRoute authenticated={authenticated} exact component={MyCvs} path={ROUTES.mycvs} />
        <PrivateRoute
          authenticated={authenticated}
          exact
          component={TableTemplate}
          path={ROUTES.table}
        />
        <PrivateRoute
          authenticated={authenticated}
          exact
          component={Download}
          path={`${ROUTES.mycvs}/:id`}
        />
        <PrivateRoute
          authenticated={authenticated}
          exact
          component={EmployeeСv}
          path={`${ROUTES.employeecv}`}
        />

        <Route path="*" element={<h1>Error 404 Page not found !!!</h1>} />
        {/* <Route exact path="/" element={<App />} />
        <Route exact path="/user:id" element={<Download />} />
        <Route exact path="/user/:name/:id" element={<Download />} />
        <Route exact path="/mycvs" element={<MyCvs />} />
        <Route exact path="/allcvs" element={<AllCvs />} /> */}
        {/*<Route path="*" element={<Navigate to="/" />} />*/}
      </Switch>
      <Footer />
    </GlobalProvider>
  );
};
